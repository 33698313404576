<template>
  <p>{{ text }}</p>
</template>

<script lang="ts">
import { defineComponent, computed, toRefs } from 'vue';

export default defineComponent({
  name: 'PaginationCounter',

  props: {
    offset: {
      type: Number,
      required: true
    },

    limit: {
      type: Number,
      required: true
    },

    total: {
      type: Number,
      required: true
    }
  },

  setup(props) {

    const { offset, limit, total } = toRefs(props);

    const text = computed<string>(() => {
      return `Showing 
          ${offset.value + 1} 
          to ${offset.value + limit.value > total.value
            ? total.value
            : offset.value + limit.value} 
          of ${total.value} entries`;
    });

    return { text };
    
  },
});
</script>
