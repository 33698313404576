
  import { defineComponent, reactive, toRefs, computed, ref, onMounted } from 'vue';
  import { useToast } from "vue-toastification";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { myGoalsService } from '@/services';
  import { IGoal, ICategory, IGoalsResponse, TIndexedObject } from '@/types';
  import { predefinedGoalsParams, GOALS_PAGINATION_LIMIT } from '@/views/admin/goals/api-params';
  import { downloadHelper } from '@/core/helper-functions';

  export default defineComponent({
    name: 'GoalsList',

    components: { AppIcon, AppTable, PaginationCounter, AppButton, AppTabs, AppModal, AppTruncatedTooltip, AppSelect },

    setup() {
      const toast = useToast();
      const state = reactive({
        goalsList: [] as IGoal[],
        goalsTotal: 0 as number,
        goalsType: { id: '1', label: 'Active', filterValue: [{ "status": "predefined" }] } as any,
        sortListBy: [] as Array<string[]>,
        activeTab: { value: 'predefined', label: 'Predefined' } as TIndexedObject,
      });
      const tabs: TIndexedObject[] = [
        { value: 'predefined', label: 'Active' },
        { value: 'archived', label: 'Archived' },
        { value: 'custom', label: 'Custom' },
      ];
      const archiveGoalId = ref<string | number>('');
      const isOpenArchiveConfirmModal = ref<boolean>(false);
      const showExportModal = ref<boolean>(false);
      const currentPage = ref<number>(0);
      const typesList = ref([
        { id: '1', label: 'Active', filterValue: [{ "status": "predefined" }] },
        { id: '2', label: 'Archived', filterValue: [{ "status": "archived" }] },
        { 
          id: '3', 
          label: 'Custom', 
          filterValue: [{ "status": { "$in": ["opened", "achieved"] }, "parentId": null }]
        },
        { 
          id: '4', 
          label: 'All', 
          filterValue: [
            {
              "status": "predefined"
            },
            {
              "status": "archived"
            }, 
            {
              "status": {
                "$in": ["opened", "achieved"]
              },
              "parentId": null
            }
          ]
        }
      ]);
      const { goalsList, goalsTotal, activeTab, goalsType } = toRefs(state);
      const tableHeaders = computed(() => [
        { 
          property: 'title', 
          label: activeTab.value.value === 'custom' ? 'Custom goals' : 'Predefined goals', 
          sortable: true, 
          minWidth: 200 
        },
        { property: 'tasksCount', label: 'Tasks', sortable: true, minWidth: 100 },
        { property: 'category', label: 'Category', sortable: true, minWidth: 180 },
        { property: 'status', label: 'Goal status', sortable: true, minWidth: 180, show: activeTab.value.value === 'custom' },
        { property: 'inProgressDerivedGoalsCount', label: 'In Progress', sortable: true, minWidth: 180, show: activeTab.value.value !== 'custom' },
        { property: 'completedDerivedGoalsCount', label: 'Completed', sortable: true, minWidth: 180, show: activeTab.value.value !== 'custom' },
      ]);
      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: handleUpdate, customClass: ' text-dark-fp-20' },
        { title: 'Archive', icon: 'archive', handler: openConfirmationModal, customClass: ' text-dark-fp-20' },

      ]);

      const offset = computed<number>(() => {
        return currentPage.value * GOALS_PAGINATION_LIMIT;
      });

      function handleUpdate({ id }: IGoal) {
        if (activeTab.value.value === 'predefined') {
          router.push({ name: routesNames.adminUpdateGoal, query: { id } });
        }
      }

      function onCurrentPageChanged(page: number) {
        currentPage.value = page - 1;

        fetchGoals();
      }

      function mapGoalsList(goals: IGoal[]) {
        goalsList.value = goals.map((el: IGoal) => {
          return {
            ...el,
            category: (el.category as ICategory).name
          };
        });
      }

      function handleSort({ orderBy, order }: any) {
        // Set appropriate values to order property
        if (order) {
          if (orderBy === 'category') {
            state.sortListBy = [['category', 'name', order]];
          } else {
            state.sortListBy = [[orderBy, order]];
          }
        } else {
          state.sortListBy = [];
        }

        fetchGoals();
      }

      function onTabChanged() {
        currentPage.value = 0;
        fetchGoals();
      }

      function openConfirmationModal({ id }: IGoal, handleHidePopper: any) {
        handleHidePopper();
        archiveGoalId.value = id;
        isOpenArchiveConfirmModal.value = true;
      }

      function hideConfirmationModal() {
        isOpenArchiveConfirmModal.value = false;
      }

      function openShowExportModal() {
        showExportModal.value = true;
      }

      function closeExportModal() {
        showExportModal.value = false;
      }

      async function confirmArchive() {
        hideConfirmationModal();

        vuex.setLoading(true);

        await myGoalsService.archiveGoal(archiveGoalId.value)
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));

        fetchGoals();
      }

      async function fetchGoals() {
        vuex.setLoading(true);

        return myGoalsService.fetchPredefinedGoals(
          predefinedGoalsParams(offset.value, state.sortListBy, activeTab.value.value)
        )
          .then((res: IGoalsResponse) => {
            mapGoalsList(res.data);
            goalsTotal.value = res.totalCount;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return myGoalsService.downloadGoals(goalsType.value.filterValue, state.sortListBy)
          .then((res: any) => {
            downloadHelper(res, `Goals "${goalsType.value.label}"`, 'csv');
            closeExportModal();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(async () => {
        await fetchGoals();
      });

      return {
        routesNames,
        router,
        goalsList,
        tableHeaders,
        actions,
        goalsTotal,
        offset,
        GOALS_PAGINATION_LIMIT,
        tabs,
        activeTab,
        isOpenArchiveConfirmModal,
        typesList,
        goalsType,
        showExportModal,

        openShowExportModal,
        closeExportModal,
        onCurrentPageChanged,
        handleSort,
        onTabChanged,
        confirmArchive,
        hideConfirmationModal,
        handleUpdate,
        onExport
      };
    }

  });
