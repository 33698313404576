<template>
  <div class="bg-white rounded-5 p-25">
    <div class="flex items-center justify-between mb-25">
      <div class="flex items-center">
        <AppIcon
          name="goals"
          size="30"
          class="text-blue-ap-100 mr-20"
        />
        <p class="text-2xl">Goals</p>
      </div>
      <div class="flex items-end">
        <AppButton 
          type="primary" 
          plain 
          class="px-30 py-15 mr-15" 
          :disabled="!goalsList.length"
          @click="openShowExportModal"
        >
          Export in CSV
        </AppButton>

        <AppButton
          type="primary"
          class="mr-10 px-30"
          icon-size="14"
          icon="plus"
          @click="router.push({ name: routesNames.adminAddGoal })"
        >
          Add New Goal
        </AppButton>
      </div>
    </div>

    <div class="mb-25 flex">
      <AppTabs
        v-model="activeTab"
        custom-tab-classes="text-center"
        active-classees="bg-blue-ap-100"
        :items="tabs"
        @change="onTabChanged"
      />
    </div>

    <div>
      <AppTable
        :dataset="goalsList"
        :headers="tableHeaders"
        class="goals-table"
        rowHeight="50px"
        type="admin"
        theme="primary"
        :show-actions="activeTab.value === 'predefined'"
        @sort-by="handleSort"
      >
        <!-- R O W S -->
        <template #title="{ row }">
          <AppTruncatedTooltip
            class="text-dark-cl-20"
            :content="row.title"
            hide-on-click
            :append-to-body="false"
            @click="handleUpdate(row)"
          />
        </template>

        <template #tasks="{ row }">
          {{ row.tasks ? row.tasks.length : 0 }}
        </template>

        <template #status="{ row }">
          {{ row.status === 'opened' ? 'In progress' : 'Completed' }}
        </template>

        <!-- A C T I O N S -->
        <template #actions="{ row, handleHidePopper }">
          <ul class="actions-wrapper py-10">
            <li
              v-for="(action, i) in actions"
              :key="i"
              class="action flex items-center cursor-pointer py-7 px-10"
              :class="action.customClass"
              @click="action.handler(row, handleHidePopper)"
            >
              <AppIcon
                :name="action.icon"
                size="12"
                class="mr-10"
              />
              <span>{{ action.title }}</span>
            </li>
          </ul>
        </template>
      </AppTable>

      <!-- P A G I N A T I O N -->
      <div
        v-if="goalsList.length"
        class="flex justify-between items-center pt-20 border-t border-grey-fp-10"
      >
        <PaginationCounter
          :offset="offset"
          :limit="GOALS_PAGINATION_LIMIT"
          :total="goalsTotal"
          class="text-posey-blue"
        />

        <v-pagination
          :key="activeTab.value"
          :page-size="GOALS_PAGINATION_LIMIT"
          layout="prev, pager, next"
          hide-on-single-page
          :total="goalsTotal"
          @current-change="onCurrentPageChanged"
        />
      </div>
    </div>

    <!-- A R C H I V E D  C O N F I R M A T I O N -->
    <portal
      v-if="isOpenArchiveConfirmModal || showExportModal"
      to="admin"
    >
      <AppModal v-if="isOpenArchiveConfirmModal" @cancel="hideConfirmationModal">
        <div class="w-full min-w-280 max-w-400">
          <p class="text-dark-cl-20 text-md mb-8">Archive goal</p>
          <p class="text-grey-fp-60 mb-40">Are you sure you want to archive this goal?</p>
          <div class="flex items-center justify-end">
            <AppButton
              type="primary"
              size="mini"
              class="px-20 mr-10"
              @click="hideConfirmationModal"
            >
              Close
            </AppButton>
            <AppButton
              size="mini"
              plain
              class="px-20"
              @click="confirmArchive"
            >
              Yes
            </AppButton>
          </div>
        </div>
      </AppModal>

      <AppModal v-else @cancel="closeExportModal">
        <div class="w-full min-w-280 max-w-400">
          <p class="text-dark-cl-20 text-md mb-8">Export Goals in CSV</p>
          <p class="text-grey-fp-60 mb-20">Please select type of Goals you want to export</p>
          <AppSelect
            v-model="goalsType"
            key-value="id"
            :options="typesList"
            class="mb-40"
          />
          <div class="flex items-center justify-end">
            <AppButton
              type="primary"
              size="mini"
              class="px-20 mr-10"
              @click="onExport"
            >
              Export
            </AppButton>
            <AppButton
              size="mini"
              plain
              class="px-20"
              @click="closeExportModal"
            >
              Cancel
            </AppButton>
          </div>
        </div>
      </AppModal>
    </portal>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, computed, ref, onMounted } from 'vue';
  import { useToast } from "vue-toastification";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

  import { routesNames, router } from '@/router';
  import { vuex } from '@/store';
  import { myGoalsService } from '@/services';
  import { IGoal, ICategory, IGoalsResponse, TIndexedObject } from '@/types';
  import { predefinedGoalsParams, GOALS_PAGINATION_LIMIT } from '@/views/admin/goals/api-params';
  import { downloadHelper } from '@/core/helper-functions';

  export default defineComponent({
    name: 'GoalsList',

    components: { AppIcon, AppTable, PaginationCounter, AppButton, AppTabs, AppModal, AppTruncatedTooltip, AppSelect },

    setup() {
      const toast = useToast();
      const state = reactive({
        goalsList: [] as IGoal[],
        goalsTotal: 0 as number,
        goalsType: { id: '1', label: 'Active', filterValue: [{ "status": "predefined" }] } as any,
        sortListBy: [] as Array<string[]>,
        activeTab: { value: 'predefined', label: 'Predefined' } as TIndexedObject,
      });
      const tabs: TIndexedObject[] = [
        { value: 'predefined', label: 'Active' },
        { value: 'archived', label: 'Archived' },
        { value: 'custom', label: 'Custom' },
      ];
      const archiveGoalId = ref<string | number>('');
      const isOpenArchiveConfirmModal = ref<boolean>(false);
      const showExportModal = ref<boolean>(false);
      const currentPage = ref<number>(0);
      const typesList = ref([
        { id: '1', label: 'Active', filterValue: [{ "status": "predefined" }] },
        { id: '2', label: 'Archived', filterValue: [{ "status": "archived" }] },
        { 
          id: '3', 
          label: 'Custom', 
          filterValue: [{ "status": { "$in": ["opened", "achieved"] }, "parentId": null }]
        },
        { 
          id: '4', 
          label: 'All', 
          filterValue: [
            {
              "status": "predefined"
            },
            {
              "status": "archived"
            }, 
            {
              "status": {
                "$in": ["opened", "achieved"]
              },
              "parentId": null
            }
          ]
        }
      ]);
      const { goalsList, goalsTotal, activeTab, goalsType } = toRefs(state);
      const tableHeaders = computed(() => [
        { 
          property: 'title', 
          label: activeTab.value.value === 'custom' ? 'Custom goals' : 'Predefined goals', 
          sortable: true, 
          minWidth: 200 
        },
        { property: 'tasksCount', label: 'Tasks', sortable: true, minWidth: 100 },
        { property: 'category', label: 'Category', sortable: true, minWidth: 180 },
        { property: 'status', label: 'Goal status', sortable: true, minWidth: 180, show: activeTab.value.value === 'custom' },
        { property: 'inProgressDerivedGoalsCount', label: 'In Progress', sortable: true, minWidth: 180, show: activeTab.value.value !== 'custom' },
        { property: 'completedDerivedGoalsCount', label: 'Completed', sortable: true, minWidth: 180, show: activeTab.value.value !== 'custom' },
      ]);
      const actions = ref([
        { title: 'Edit', icon: 'pen', handler: handleUpdate, customClass: ' text-dark-fp-20' },
        { title: 'Archive', icon: 'archive', handler: openConfirmationModal, customClass: ' text-dark-fp-20' },

      ]);

      const offset = computed<number>(() => {
        return currentPage.value * GOALS_PAGINATION_LIMIT;
      });

      function handleUpdate({ id }: IGoal) {
        if (activeTab.value.value === 'predefined') {
          router.push({ name: routesNames.adminUpdateGoal, query: { id } });
        }
      }

      function onCurrentPageChanged(page: number) {
        currentPage.value = page - 1;

        fetchGoals();
      }

      function mapGoalsList(goals: IGoal[]) {
        goalsList.value = goals.map((el: IGoal) => {
          return {
            ...el,
            category: (el.category as ICategory).name
          };
        });
      }

      function handleSort({ orderBy, order }: any) {
        // Set appropriate values to order property
        if (order) {
          if (orderBy === 'category') {
            state.sortListBy = [['category', 'name', order]];
          } else {
            state.sortListBy = [[orderBy, order]];
          }
        } else {
          state.sortListBy = [];
        }

        fetchGoals();
      }

      function onTabChanged() {
        currentPage.value = 0;
        fetchGoals();
      }

      function openConfirmationModal({ id }: IGoal, handleHidePopper: any) {
        handleHidePopper();
        archiveGoalId.value = id;
        isOpenArchiveConfirmModal.value = true;
      }

      function hideConfirmationModal() {
        isOpenArchiveConfirmModal.value = false;
      }

      function openShowExportModal() {
        showExportModal.value = true;
      }

      function closeExportModal() {
        showExportModal.value = false;
      }

      async function confirmArchive() {
        hideConfirmationModal();

        vuex.setLoading(true);

        await myGoalsService.archiveGoal(archiveGoalId.value)
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));

        fetchGoals();
      }

      async function fetchGoals() {
        vuex.setLoading(true);

        return myGoalsService.fetchPredefinedGoals(
          predefinedGoalsParams(offset.value, state.sortListBy, activeTab.value.value)
        )
          .then((res: IGoalsResponse) => {
            mapGoalsList(res.data);
            goalsTotal.value = res.totalCount;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function onExport() {
        vuex.setLoading(true);

        return myGoalsService.downloadGoals(goalsType.value.filterValue, state.sortListBy)
          .then((res: any) => {
            downloadHelper(res, `Goals "${goalsType.value.label}"`, 'csv');
            closeExportModal();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(async () => {
        await fetchGoals();
      });

      return {
        routesNames,
        router,
        goalsList,
        tableHeaders,
        actions,
        goalsTotal,
        offset,
        GOALS_PAGINATION_LIMIT,
        tabs,
        activeTab,
        isOpenArchiveConfirmModal,
        typesList,
        goalsType,
        showExportModal,

        openShowExportModal,
        closeExportModal,
        onCurrentPageChanged,
        handleSort,
        onTabChanged,
        confirmArchive,
        hideConfirmationModal,
        handleUpdate,
        onExport
      };
    }

  });
</script>

<style lang="scss" scoped>
  .goals-table {
    max-height: 450px;
  }
</style>